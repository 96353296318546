.events {
    &__wrapper {
        display: flex;
        flex-direction: column;
        gap: 2rem;

        a {
            text-decoration: none !important;
            color: $white !important;
        }

        &__item {
            display: flex;
            flex-direction: column;
            position: relative;
            min-height: 300px;
            transition: .3s all ease-in-out;
            border-radius: $br-10;
            overflow: hidden;

            &:hover {
                cursor: pointer;
                transform: scale(1.03);
            }

            &__image {
                position: absolute;
                top: 0;
                left: 0;
                width: 100%;
                height: 100%;
                transition: .3s all ease-in-out;

                img {
                    width: 100%;
                    height: 100%;
                    object-fit: cover;
                    object-position: center;
                }
            }

            &__content {
                background: rgba(0,0,0,.7);
                position: relative;
                width: 100%;
                height: 100%;
                flex: 1;
                display: flex;
                flex-direction: column;
                justify-content: flex-end;
                padding: 20px;
                transition: .3s all ease-in-out;

                &:hover {
                    background: rgba(0,0,0,.5);
                }

                &.read-more {
                    background: darken($primary, 10%);
                    display: flex;
                    justify-content: space-between;

                    &:hover {
                        background: darken($primary, 0%);
                    }

                    h2 {
                        margin-bottom: 20px;
                        color: $black;
                        font-size: 2.5rem;
                    }

                    h3 {
                        text-align: right;
                        font-size: 1.5rem;

                        i {
                            margin-left: 20px;
                        }
                    }
                }
            }
        }
    }
}

.event {
    &-page {
        header {
            height: 40vh;
            display: flex;
            align-items: center;
            justify-content: center;

            .overlay {
                position: absolute;
                top: 0;
                left: 0;
                width: 100%;
                height: 100%;
                // background gradient
                background: linear-gradient(rgba(33, 37, 41, .4), rgba(33, 37, 41, .8));
            }

            h1 {
                font-size: 3rem;
                color: $primary;
                text-align: center;
            }
        }

        &__head {
            text-align: center;
        }

        &__content {
            padding-top: 50px;
            padding-bottom: 50px;

            &__tickets {
                &__wrapper {
                    display: flex;
                    flex-direction: column;
                    gap: 1rem;

                    &__item {
                        background: $white;
                        border-radius: $br-10;
                        padding: 20px;

                        .ticket {
                            &__header {
                                display: flex;
                                justify-content: space-between;
                                align-items: center;
                                margin-bottom: 20px;
                                padding-bottom: 20px;
                                border-bottom: 1px solid $gray-400;

                                &__title {
                                    font-size: 1.5rem;
                                    color: $primary;
                                    font-family: $font-primary;
                                }

                                &__price {
                                    display: flex;
                                    flex-direction: row;
                                    gap: 1rem;
                                    align-items: center;

                                    &__price {
                                        font-size: 1.5rem;
                                        color: $black;
                                        font-family: $font-primary;
                                    }
                                }
                            }

                            &__content {
                                display: flex;
                                flex-direction: column;
                                gap: 1rem;

                                &__title {
                                    font-size: 1rem;
                                    color: $primary;
                                    font-family: $font-primary;
                                }

                                &__description {
                                    font-size: .8rem;
                                    color: $black;
                                    font-family: $font-secondary;
                                }
                            }
                        }
                    }
                }
            }
        }
    }
}
