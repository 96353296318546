body {
    background-color: $body-bg;
    font-family: $font-secondary !important;
}

h1, h2, h3, h4, h5, h6 {
    position: relative;
    font-family: $font-primary !important;
}

.btn {
    transition: .3s all ease-in-out !important;
    cursor: pointer;
    border-radius: $br-10;
    padding: 10px 20px !important;

    &:hover {
        transform: scale(1.05);
    }

    &-licht {
        font-family: $font-primary !important;
        background: $primary;
        color: $white;
        border: none;
        padding: 1rem 2rem;

        &:hover {
            background: darken($primary, 10%);
            color : $white;
        }
    }

    &-donker {
        font-family: $font-primary !important;
        background: $black;
        color: $white;
        border: none;
        padding: 1rem 2rem;

        &:hover {
            background: lighten($black, 10%);
            color : $white;
        }
    }
}

.ck-editor__editable_inline {
    min-height: 400px;
}

.font-primary {
    font-family: $font-primary !important;
}

.font-secondary {
    font-family: $font-secondary !important;
}

footer {
    .betaalmethodes {
        display: flex;
        flex-wrap: wrap;
        flex-direction: row;
        gap: .5rem;

        img {
            width: 50px;
        }
    }
}

.legal {
    color: $white;

    h4 {
        color: $primary;
    }
}

.lcc-backdrop {
    z-index: 999 !important;
}

.lcc-modal.lcc-modal--settings {
    z-index: 999999999999999999999999999999 !important;
}

.lcc-modal {
    left: unset !important;
    top: unset !important;
    right: 5%;
    bottom: 5%;
    transform: unset !important;
    z-index: 999999999999999999999999999999 !important;
    font-family: $font-secondary !important;
}

.lcc-modal__actions {
    display: flex !important;
    flex-direction: row !important;
    gap: 1rem !important;
    justify-content: center !important;

    @media (max-width: $breakpoint-md) {
        flex-direction: column !important;
    }

    button {
        flex-basis: 50%;
        font-family: $font-primary !important;
        padding: 1rem 2rem !important;

        @media (max-width: $breakpoint-md) {
            flex-basis: 100%;
        }
    }
}
