// Fonts
@import url('https://fonts.googleapis.com/css?family=Nunito');
@import url('https://cdnjs.cloudflare.com/ajax/libs/font-awesome/5.15.4/css/all.min.css');
@import '~noty/src/noty';
@import '~noty/src/themes/bootstrap-v4';

// Variables
@import 'variables';

// Bootstrap
@import '~bootstrap/scss/bootstrap';

// Custom code
@import 'main';
