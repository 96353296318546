// Body
$body-bg: #212529; // rgba(33, 37, 41, 1.00)

// Colors
$primary: #EF7900; // rgba(239, 121, 0, 1.00)
$black: #000000; // rgba(0, 0, 0, 1.00)
$white: #FFFFFF; // rgba(255, 255, 255, 1.00)
$gray: #F5F5F5; // rgba(245, 245, 245, 1.00)
$gray-dark: #343A40; // rgba(52, 58, 64, 1.00)
$light: #F8F9FA; // rgba(248, 249, 250, 1.00)

// border radius
$br-10: 10px;

// Typography
$font-primary: 'Concert One', sans-serif;
$font-secondary: 'Valera Round', sans-serif;
$font-family-sans-serif: 'Nunito', sans-serif;
$font-size-base: 0.9rem;
$line-height-base: 1.6;

// Breakpoints
$breakpoint-xs: 0;
$breakpoint-sm: 576px;
$breakpoint-md: 768px;
$breakpoint-lg: 992px;
$breakpoint-xl: 1199px;

