:root{
    --cc-bg: #ffffff;
    --cc-primary-color: #2c2f31;
    --cc-secondary-color: #5e6266;

    --cc-btn-primary-bg: #EF7900;
    --cc-btn-primary-color: #ffffff;
    --cc-btn-primary-border-color: var(--cc-btn-primary-bg);
    --cc-btn-primary-hover-bg: #000000;
    --cc-btn-primary-hover-color: #ffffff;
    --cc-btn-primary-hover-border-color: var(--cc-btn-primary-hover-bg);

    --cc-btn-secondary-bg: #eaeff2;
    --cc-btn-secondary-color: var(--cc-primary-color);
    --cc-btn-secondary-border-color: var(--cc-btn-secondary-bg);
    --cc-btn-secondary-hover-bg: #d4dae0;
    --cc-btn-secondary-hover-color: #000000;
    --cc-btn-secondary-hover-border-color: #d4dae0;

    --cc-separator-border-color: #f0f4f7;

    --cc-toggle-on-bg: var(--cc-btn-primary-bg);
    --cc-toggle-off-bg: #667481;
    --cc-toggle-on-knob-bg: #ffffff;
    --cc-toggle-off-knob-bg: var(--cc-toggle-on-knob-bg);

    --cc-toggle-enabled-icon-color: var(--cc-bg);   // yes (v tick)
    --cc-toggle-disabled-icon-color: var(--cc-bg);  // no (x tick)

    --cc-toggle-readonly-bg: #d5dee2;
    --cc-toggle-readonly-knob-bg: #fff;
    --cc-toggle-readonly-knob-icon-color: var(--cc-toggle-readonly-bg);

    --cc-section-category-border: var(--cc-cookie-category-block-bg);

    --cc-cookie-category-block-bg: #f0f4f7;
    --cc-cookie-category-block-border: #f0f4f7;
    --cc-cookie-category-block-hover-bg: rgba(239, 121, 0, .1);
    --cc-cookie-category-block-hover-border: #e9eff4;
    --cc-cookie-category-expanded-block-bg: transparent;
    --cc-cookie-category-expanded-block-hover-bg: #dee4e9;

    --cc-overlay-bg: rgba(0, 0, 0, 0.65);
    --cc-webkit-scrollbar-bg: var(--cc-section-category-border);
    --cc-webkit-scrollbar-hover-bg: var(--cc-btn-primary-hover-bg);

    --cc-footer-bg: var(--cc-btn-secondary-bg);
    --cc-footer-color: var(--cc-secondary-color);
    --cc-footer-border-color: #e4eaed;
}
